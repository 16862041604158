import React from "react";
import Navbar from "scenes/navbar";
import LayerTop from "scenes/navbartop/indextop";
import Footer from "scenes/footer";

const Members = () => {
  return (
    <>
      <LayerTop /> {/* First Navbar */}
      <Navbar /> {/* Second Navbar */}
      <section>
        <div className="row">
          <h1 className="h1text">Our Team</h1>
        </div>
        <div className="row">
          {/* Column 1*/}
          <div className="column">
            <div className="card">
              <div className="img-container">
                <img
                  src="https://pbs.twimg.com/profile_images/1608914392287936515/gQLb4KdN_400x400.jpg"
                  alt="Swooce Profile Picture"
                />
              </div>
              <h3 className="h3text">Swooce</h3>
              <p>Owner</p>
              <div className="icons">
                <a href="https://twitter.com/SkinPawn">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://steamcommunity.com/id/SwooceCS/">
                  <i class="fa-brands fa-steam"></i>
                </a>
                <a href="https://discord.com/invite/uCYZhJNJ6G">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
          </div>
          {/* Column 2*/}
          <div className="column">
            <div className="card">
              <div className="img-container">
                <img
                  src="https://pbs.twimg.com/profile_images/1731011204955430912/KINE95v4_400x400.jpg"
                  alt="Max Profile Picture"
                />
              </div>
              <h3 className="h3text">Maxthelost</h3>
              <p>Co-Owner</p>
              <div className="icons">
                <a href="https://twitter.com/MaxthelostCSGO">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://steamcommunity.com/id/SkinPawnCS/">
                  <i class="fa-brands fa-steam"></i>
                </a>
                <a href="https://discord.com/invite/uCYZhJNJ6G">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
          </div>
          {/* Column 3*/}
          <div className="column">
            <div className="card">
              <div className="img-container">
                <img
                  src="https://pbs.twimg.com/profile_images/1758464310819295232/vVWSolrm_400x400.jpg"
                  alt="Kris Profile Picture"
                />
              </div>
              <h3 className="h3text">KrisGFX</h3>
              <p>Developer/Collector</p>
              {/* <p>This guy is a very cool developer</p> */}
              <div className="icons">
                <a href="https://twitter.com/KrisGfx">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://steamcommunity.com/id/Krisgfx/">
                  <i class="fa-brands fa-steam"></i>
                </a>
                <a href="https://discord.com/invite/uCYZhJNJ6G">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <h1 className="h1text">Partners:</h1>
          <div className="column">
            <div className="card">
              <div className="img-container">
                <img
                  src="https://pbs.twimg.com/profile_images/1827085382531297280/tT6bwamb_400x400.jpg"
                  alt="Priority Profile Picture"
                />
              </div>
              <h3 className="h3text">Priority Esports</h3>
              <p>Esports Organization</p>
              {/* <p>This guy is a very cool developer</p> */}
          {/*
              <div className="icons">
                <a href="https://x.com/PriorityCS2">
                  <i className="fab fa-twitter"></i>
                </a>
                <div className="svg-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="black"
                      d="M23.999 2.705a.167.167 0 0 0-.312-.1a1141 1141 0 0 0-6.053 9.375H.218c-.221 0-.301.282-.11.352c7.227 2.73 17.667 6.836 23.5 9.134c.15.06.39-.08.39-.18z"
                    />
                  </svg>
                </div>
                <a href="https://discord.gg/priority-esports">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Footer sx={{ position: "absolute", bottom: 0, width: "100%" }} />
    </>
  );
};

export default Members;
